import React from 'react';
import {View, Image, ImageRequireSource} from 'react-native';
import Text from '@basicComponents/text';
import theme from '@style';
import {useSettingWindowDimensions} from '@/store/useSettingStore';
import Button from '@/components/basic/button';
import {useToken} from '@/store/useUserStore';
import {goTo} from '@/utils';

type BtnState = {
  title: string;
  subTitle: string;
  source: ImageRequireSource;
  stepSource: ImageRequireSource;
  isRight?: boolean;
};

const HomeGameStep = () => {
  const {screenWidth, calculateItemWidth} = useSettingWindowDimensions();
  const itemWidth = screenWidth - 12 * 2;
  const itemImageWH = calculateItemWidth(150);
  const {isLogin} = useToken();

  const renderItem = (item: BtnState) => {
    return (
      <View
        key={item?.title}
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          // eslint-disable-next-line react-native/no-inline-styles
          {width: itemWidth},
        ]}>
        {item?.isRight && (
          <Image
            style={[
              {
                height: itemImageWH,
                width: itemImageWH,
              },
            ]}
            resizeMode="stretch"
            source={item?.source}
          />
        )}
        <View style={[item?.isRight && theme.margin.rightl, theme.flex.flex1]}>
          <Image
            style={[
              {
                height: calculateItemWidth(21),
                width: calculateItemWidth(68),
              },
            ]}
            resizeMode="contain"
            source={item?.stepSource}
          />
          <Text fontFamily="fontInter" blod fontSize={24} white style={[]}>
            {item?.title}
          </Text>
          <Text
            fontFamily="fontInter"
            fontSize={12}
            color={theme.fontColor.white60}
            style={[theme.margin.topm]}>
            {item?.subTitle}
          </Text>
        </View>
        {!item?.isRight && (
          <Image
            style={[
              {
                height: itemImageWH,
                width: itemImageWH,
              },
            ]}
            resizeMode="stretch"
            source={item?.source}
          />
        )}
      </View>
    );
  };

  return (
    <View style={[theme.padding.lrl, theme.flex.centerByCol]}>
      <Image
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {marginTop: 30, height: calculateItemWidth(176), width: itemWidth},
        ]}
        source={require('@assets/imgs/home/game-step-header-image.webp')}
      />
      <View style={[theme.flex.center]}>
        <Text fontSize={24} blod color={'#AD86FF'} style={[theme.font.center]}>
          Start having fun with us
        </Text>
      </View>
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={[theme.margin.topxxl]}>
        {[
          {
            source: require('@assets/imgs/home/game-step-1-image.webp'),
            stepSource: require('@assets/imgs/home/game-step-1.webp'),
            title: 'Register',
            subTitle:
              'Creating an account feels like a big bang. It only happens once.',
          },
          {
            isRight: true,
            source: require('@assets/imgs/home/game-step-2-image.webp'),
            stepSource: require('@assets/imgs/home/game-step-2.webp'),
            title: 'Recharge',
            subTitle:
              'We have a full range of payment methods. Choose the one you like best and start your galactic journey.',
          },
          {
            source: require('@assets/imgs/home/game-step-3-image.webp'),
            stepSource: require('@assets/imgs/home/game-step-3.webp'),
            title: 'Game',
            subTitle:
              "Don't be afraid to embark on a journey into space! There will be more than 1500 locations along the way!",
          },
        ].map(item => {
          return renderItem(item);
        })}
      </View>
      {!isLogin ? (
        <View style={[{marginTop: 32}]}>
          <Button
            title={'Start Immediately'}
            type="linear-primary"
            radius={30}
            onPress={() => {
              goTo('SingUp');
            }}
          />
        </View>
      ) : null}
    </View>
  );
};

export default HomeGameStep;
