import React, {useCallback, useEffect, useState} from 'react';
// import {Image} from 'react-native';
import theme from '@style';
import globalStore from '@services/global.state';
import HomeMenu from './drawer/home-menu';
import HomeHeader from './components/home-header';
import Download from './components/download';

// import {goTo} from '@/utils';
import HomeService from './components/home-service';
import {useFocusEffect} from '@react-navigation/native';
import Drawer, {DrawerRef} from '@basicComponents/drawer/drawer';
// import {useLotteryModal} from './lottery.hooks';
// import {useTranslation} from 'react-i18next';
// import {postGetFreeLottery} from './home.service';
import {LazyImageLGBackground} from '@/components/basic/image';
import HomeCategoryPageTabs from './components/home-category-page-tabs';
import useHomeStore from '@/store/useHomeStore';
import {useToken} from '@/store/useUserStore';
import {useShallow} from 'zustand/react/shallow';
import HomeTabPageHome from './pages/tab-page-home';
// import HomePopular from './pages/tab-page-popular';
import HomeLottery from './pages/tab-page-lottery';
import HomeCasino from './pages/tab-page-casino';
import HomeTabPageLive from './pages/tab-page-live';
import useNotificationStore from '@/store/useNotificationStore';
// import {useLuckySpinActions} from '@/store/luckySpinStore';
// import HomeCheckIn from './components/home-check-in';
// import HomeInvite from './components/home-invite';
import {getInviteKongArea} from '@/common-pages/proxy-new/api';

const Home = () => {
  // const {i18n} = useTranslation();
  const {isLogin} = useToken();
  const {getLotteryPageData, getHomeBannerList, oneCategoryPageIndex} =
    useHomeStore(
      useShallow(state => ({
        oneCategoryPageIndex: state.oneCategoryPageIndex,
        getLotteryPageData: state.getLotteryPageData,
        getHomeBannerList: state.getHomeBannerList,
      })),
    );
  const {getNoticeMap, getUnReadCount} = useNotificationStore(
    useShallow(state => ({
      getNoticeMap: state.getNoticeMap,
      getUnReadCount: state.getUnReadCount,
    })),
  );
  // const {setSpinConfig} = useLuckySpinActions();

  const menuRef = React.useRef<DrawerRef>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const setMenuOpen = (bool: boolean, _unreadCount?: number) => {
    if (bool) {
      setUnreadCount(_unreadCount || 0);
      menuRef.current?.open();
    } else {
      menuRef.current?.close();
    }
  };

  // const [carList, setCarList] = useState<CarListItem[]>([]);
  const [version] = useState(0);

  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      globalStore.amountCheckOut.next();
      if (token) {
        getUnReadCount();
        // postGetFreeLottery().then(lotteryInfo =>
        //   Image.prefetch(lotteryInfo.imgUrl),
        // );
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [getUnReadCount]);
  useFocusEffect(handleFocusEffect);

  const [kongList, setKongList] = useState<Array<any>>();
  const getKong = async () => {
    const res = await getInviteKongArea(1);
    setKongList(res || []);
  };
  useEffect(() => {
    if (isLogin) {
      getNoticeMap();
      // setSpinConfig(true);
    } else {
      // setSpinConfig(false);
    }
    getHomeBannerList();
    getLotteryPageData();
    getKong();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  //freelottery
  // const {renderModal: renderLottery, show: lotteryShow} = useLotteryModal(
  //   () => {
  //     globalStore.globalTotal.next({
  //       type: 'success',
  //       message: i18n.t('home.tip.copied'),
  //     });
  //   },
  // );

  // const handleLotteryClick = async () => {
  // if (!globalStore.token) {
  //   goTo('Login');
  //   return;
  // }
  // const lotteryInfo = await postGetFreeLottery();
  // lotteryShow({
  //   current: lotteryInfo.num,
  //   total: lotteryInfo.maxNum,
  //   backgroundUrl: lotteryInfo.imgUrl,
  //   shareUrl: lotteryInfo.shareUrl,
  //   toolTipContent: lotteryInfo.contentStr,
  // });
  // };

  return (
    <LazyImageLGBackground
      showBottomBG={false}
      subtractBottomTabHeight={true}
      style={[theme.fill.fill, theme.position.rel]}>
      <HomeHeader setMenuOpen={setMenuOpen} version={version} />
      {globalStore.isWeb && !globalStore.viewType ? <Download /> : null}
      <HomeCategoryPageTabs />
      {/* {globalStore.isAndroid ? (
        <> */}
      {oneCategoryPageIndex === 0 && (
        <HomeTabPageHome kongList={kongList as any} />
      )}
      {/* {oneCategoryPageIndex === 10 && <HomePopular />} */}
      {oneCategoryPageIndex === 1 && <HomeLottery />}
      {oneCategoryPageIndex === 2 && <HomeCasino />}
      {oneCategoryPageIndex === 3 && <HomeTabPageLive />}
      {/* </>
      ) : null} */}

      {/* <HomeCheckIn />
      <HomeInvite /> */}
      <HomeService />
      {/* {renderLottery} */}
      <Drawer ref={menuRef}>
        <HomeMenu setMenuOpen={setMenuOpen} unreadCount={unreadCount} />
      </Drawer>
    </LazyImageLGBackground>
  );
};

export default Home;
