import React from 'react';
import {View, Image, ImageRequireSource, ImageBackground} from 'react-native';
import Text from '@basicComponents/text';
import theme from '@style';
import {useSettingWindowDimensions} from '@/store/useSettingStore';
// import Button from '@/components/basic/button';

type BtnState = {
  title: string;
  subTitle: string;
  source: ImageRequireSource;
};

const HomeReceiptPayment = () => {
  const {screenWidth, calculateItemWidth} = useSettingWindowDimensions();
  const itemWidth = screenWidth - 12 * 2;

  const renderItem = (item: BtnState) => {
    return (
      <ImageBackground
        key={item?.title}
        source={require('@assets/imgs/home/payment-bg-image.webp')}
        resizeMode="stretch"
        style={[
          theme.borderRadius.s,
          theme.flex.centerByCol,
          theme.borderRadius.s,
          // eslint-disable-next-line react-native/no-inline-styles
          {height: calculateItemWidth(302), width: itemWidth},
        ]}>
        <View style={[theme.flex.centerByCol, theme.margin.topxxl]}>
          <Text
            fontFamily="fontInter"
            fontSize={24}
            white
            style={[theme.font.center]}>
            {item?.title}
          </Text>
          <Text
            fontFamily="fontInter"
            fontSize={14}
            numberOfLines={2}
            color={theme.fontColor.white60}
            style={[theme.font.center, theme.margin.topxs]}>
            {item?.subTitle}
          </Text>
        </View>
        <Image
          resizeMode="stretch"
          style={[
            theme.margin.topxxl,
            // eslint-disable-next-line react-native/no-inline-styles
            {height: calculateItemWidth(188), width: calculateItemWidth(188)},
          ]}
          source={item?.source}
        />
      </ImageBackground>
    );
  };

  return (
    <View style={[theme.padding.lrl, theme.flex.centerByCol]}>
      <Image
        style={[
          theme.margin.topxxl,
          // eslint-disable-next-line react-native/no-inline-styles
          {height: 64, width: 64},
        ]}
        source={require('@assets/imgs/home/trophy.webp')}
      />
      <View style={[theme.flex.center]}>
        <Text fontSize={24} blod color={'#AD86FF'} style={[theme.font.center]}>
          Pay And Get Paid Safely
        </Text>
      </View>
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={[theme.flex.wrap, theme.margin.topxxl, {gap: 10}]}>
        {[
          {
            source: require('@assets/imgs/home/find-dollar-image.webp'),
            title: 'Convenient deposit and withdrawal',
            subTitle:
              'We support many ways of recharge and withdrawal channels',
          },
          {
            source: require('@assets/imgs/home/one-day-image.webp'),
            title: '24/7 support',
            subTitle:
              'Friendly and friendly staff will serve you. They can speak your native language.',
          },
          {
            source: require('@assets/imgs/home/responsible-image.webp'),
            title: 'Responsible Gaming',
            subTitle: 'Improve your experience with additional restrictions',
          },
        ].map(item => {
          return renderItem(item);
        })}
      </View>
      {/* <View style={[theme.margin.topl]}>
        <Button title={'Savings account'} type="linear-primary" radius={30} />
      </View> */}
    </View>
  );
};

export default HomeReceiptPayment;
