import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React, {useCallback} from 'react';
import {View, FlatList, Image, ImageRequireSource} from 'react-native';
import Text from '@basicComponents/text';
import {useTranslation} from 'react-i18next';
import theme from '@style';
import {goTo} from '@utils';
import globalStore from '@/services/global.state';
import {toLogin} from '@/pages/me/me.variable';

type BtnState = {
  title: string;
  source: ImageRequireSource;
  onPressBtn: () => void;
};

const DrawerGoldArea = ({
  onClose,
  spinShow,
}: {
  onClose: () => void;
  spinShow: () => void;
}) => {
  const {i18n} = useTranslation();

  const keyExtractor = useCallback((_: BtnState, index: number) => {
    return index.toString();
  }, []);

  const renderItem = useCallback(({item}: {item: BtnState}) => {
    return (
      <NativeTouchableOpacity
        style={[
          theme.borderRadius.s,
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.flex1,
          theme.flex.between,
          theme.padding.lrm,
          theme.border.primary50,
          // eslint-disable-next-line react-native/no-inline-styles
          {height: 42, backgroundColor: '#1F1853'},
        ]}
        activeOpacity={0.8}
        onPress={item.onPressBtn}>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            {overflow: 'hidden'},
          ]}>
          <Image style={[{height: 28, width: 28}]} source={item?.source} />
          <Text
            fontFamily="fontInter"
            fontSize={14}
            white
            style={[theme.margin.leftm]}>
            {item?.title}
          </Text>
        </View>
        {/* <Image
          style={[{height: 24, width: 24}]}
          source={require('@assets/icons/home/home-menu/row_right.webp')}
        /> */}
      </NativeTouchableOpacity>
    );
  }, []);

  return (
    <View style={[theme.flex.row, theme.padding.l]}>
      <FlatList<BtnState>
        data={[
          {
            source: require('@assets/icons/home/home-menu/vipclub.webp'),
            title: i18n.t('homeMenu.title.vipclub'),
            onPressBtn: () => {
              onClose();
              // if (!globalStore.token) {
              //   toLogin();
              //   return;
              // }
              goTo('VipClub');
              // globalStore.globalSucessTotal('Stay tuned');
            },
          },
          {
            source: require('@assets/icons/home/home-menu/betRebate.webp'),
            title: i18n.t('homeMenu.title.rebate'),
            onPressBtn: () => {
              onClose();
              if (!globalStore.token) {
                toLogin();
                return;
              }
              goTo('Rebate');
            },
          },
          {
            source: require('@assets/icons/home/home-menu/luckyspin.webp'),
            title: i18n.t('homeMenu.title.luckyspin'),
            onPressBtn: () => {
              onClose();
              spinShow();
            },
          },
          {
            source: require('@assets/icons/home/home-menu/freelottery.webp'),
            title: i18n.t('homeMenu.title.collect'),
            onPressBtn: () => {
              onClose();
              goTo('CollectPage');
            },
          },
          {
            source: require('@assets/icons/home/home-menu/coin.webp'),
            title: 'Shop',
            onPressBtn: () => {
              onClose();
              goTo('ShoppingPage');
            },
          },
          //暂时注释
          // {
          //   source: require('@assets/icons/home/home-menu/coin.webp'),
          //   title: 'Piggy Pot',
          //   onPressBtn: () => {
          //     onClose();
          //     goTo('PiggyPot');
          //   },
          // },
          // {
          //   source: require('@assets/icons/home/home-menu/coin.webp'),
          //   title: 'Mine Clearance',
          //   onPressBtn: () => {
          //     onClose();
          //     goTo('MineClearance');
          //   },
          // },
        ]}
        renderItem={renderItem}
        numColumns={2}
        keyExtractor={keyExtractor}
        // eslint-disable-next-line react-native/no-inline-styles
        columnWrapperStyle={{
          marginBottom: 8,
          gap: 8,
        }}
      />
    </View>
  );
};

export default DrawerGoldArea;
