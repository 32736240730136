import React from 'react';
import {View, Image, ImageRequireSource} from 'react-native';
import Text from '@basicComponents/text';
import {useTranslation} from 'react-i18next';
import theme from '@style';
import {useSettingWindowDimensions} from '@/store/useSettingStore';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {goTo} from '@/utils';

type BtnState = {
  title: string;
  subTitle: string;
  source: ImageRequireSource;
  pressTitle: string;
  onPress: () => void;
};

interface HomeRewardsProps {}

const HomeRewards = ({}: HomeRewardsProps) => {
  const {i18n} = useTranslation();
  const {screenWidth, calculateItemWidth} = useSettingWindowDimensions();
  const itemWidth = screenWidth - 12 * 2;
  const headerImageWH = calculateItemWidth(68);

  const renderItem = (item: BtnState) => {
    return (
      <View
        key={item?.title}
        style={[
          theme.flex.row,
          theme.borderRadius.s,
          // eslint-disable-next-line react-native/no-inline-styles
          {width: itemWidth, height: 88},
        ]}>
        <Image
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {height: headerImageWH, width: headerImageWH},
          ]}
          source={item?.source}
        />
        <View style={[theme.flex.flex1, theme.margin.leftm, theme.flex.around]}>
          <Text fontFamily="fontInter" fontSize={18} white>
            {item?.title}
          </Text>
          <Text
            numberOfLines={2}
            fontFamily="fontInter"
            fontSize={12}
            color={theme.fontColor.white80}>
            {item?.subTitle}
          </Text>
          <NativeTouchableOpacity
            onPress={item?.onPress}
            style={[theme.flex.row, theme.flex.centerByCol]}>
            <Text
              fontFamily="fontInter"
              color={theme.fontColor.primary}
              fontSize={12}
              white>
              {item?.pressTitle}
            </Text>
            <Image
              style={[theme.icon.s]}
              source={require('@assets/imgs/home/press-right.webp')}
            />
          </NativeTouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <View style={[theme.padding.lrl, theme.flex.centerByCol, {marginTop: 30}]}>
      <View style={[theme.flex.center]}>
        <Text fontSize={24} blod color={'#AD86FF'} style={[theme.font.center]}>
          We got something interesting for you
        </Text>
      </View>
      <View
        // eslint-disable-next-line react-native/no-inline-styles
        style={[{gap: 10}]}>
        {[
          {
            source: require('@assets/imgs/home/rewards1.webp'),
            title: 'Invite Friends',
            subTitle:
              'Invite your friends to play games together and earn a lot of cash',
            pressTitle: 'Start earning money',
            onPress: () => {
              goTo('Referral');
            },
          },
          {
            source: require('@assets/imgs/home/rewards2.webp'),
            title: 'Promotions',
            subTitle: 'Many promotions waiting for you to claim your bonuses',
            pressTitle: 'Get your bonus now',
            onPress: () => {
              goTo('Promotion');
            },
          },
          {
            source: require('@assets/imgs/home/rewards3.webp'),
            title: i18n.t('homeMenu.title.luckyspin'),
            subTitle:
              'We have prepared a free lucky wheel for you, go and spin it',
            pressTitle: 'Go Spin',
            onPress: () => {
              goTo('LuckySpinPage');
            },
          },
          {
            source: require('@assets/imgs/home/rewards4.webp'),
            title: 'Competition',
            subTitle:
              'Participate in exciting competitions and win more prizes',
            pressTitle: 'Join the competition now',
            onPress: () => {},
          },
        ].map(item => {
          return renderItem(item);
        })}
      </View>
    </View>
  );
};

export default HomeRewards;
